<template>
  <el-container class="maincontainer">
    <el-aside class="menueaside">
      <div class="logo">
        <div class="logo_box">
          <img :src="this.userInfo.hospitalLogo" alt="" />
        </div>
      </div>
      <div class="menuebox">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          background-color="#fff"
          text-color="#C8C9CC"
          active-text-color="#444444"
        >
          <el-menu-item index="2">
            <i class="el-icon-data-analysis"></i>
            <span slot="title">随访数据</span>
          </el-menu-item>
        </el-menu>
      </div>
    </el-aside>
    <el-container class="mainbox">
      <el-header class="headbar">
        <span>{{ userInfo.hospitalName }}随访数据统计平台</span>
        <div class="userInfobox">
          <div class="avator">
            <img :src="userInfo.avatar" alt="" />
          </div>
          <el-dropdown>
            <span class="el-dropdown-link">
              {{ userInfo.name
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><span @click="loginOut">退出登录</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <div class="contentbox">
        <router-view />
      </div>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    loginOut() {
      this.$store.dispatch('loginAbout/loginOut');
    },
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'));
    // console.log(this.userInfo);
  },
};
</script>

<style scoped lang="less">
.menueaside {
  background-color: #00163d;
  height: 100vh;
  width: 154px !important;
  display: flex;
  flex-direction: column;
  .logo {
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo_box {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ffffff;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .menuebox {
    flex: 1;
  }
}
.maincontainer {
  background-color: #f8f8fa;
  //min-width: 1600px;
  display: flex;
}
.mainbox {
  display: flex;
  flex-direction: column;
}
.headbar {
  height: 56px !important;
  background: #ffffff;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #323233;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 16px;
  }
  .userInfobox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin: 0 10px;
    }
  }
  .avator {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #155bd4;
    background-color: #ffffff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
.contentbox {
  padding: 20px;
  height: calc(100vh - 56px);
  overflow-y: auto;
  box-sizing: border-box;
}
</style>
